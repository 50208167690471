import React, { useContext, useMemo } from "react";
import {
  LanguageProvider,
  langCompare,
  useLanguage,
} from "@kogk/gatsby-plugin-i18n";
import { graphql, useStaticQuery } from "gatsby";
import { useMediaQuery } from "@kogk/common";
import "@src/data/prismic-fragments/menu";
import "@src/data/prismic-fragments/strings";
import { breakpointSm, breakpointLg } from "./breakpoints.module.scss";

const Ctx = React.createContext({
  altUrls: [],
});

const useBootstrapBreakpoints = () => {
  const isMobile = useMediaQuery(
    `screen and (max-width: ${breakpointSm - 1}px)`,
    false
  );

  const isTablet = useMediaQuery(
    `screen and (min-width: ${breakpointSm}px) and (max-width: ${
      breakpointLg - 1
    }px)`,
    false
  );

  const isMobileNavBar = useMediaQuery(
    `screen and (max-width:  1060px)`,
    false
  );

  // if it's eg. print or some other condition, a width check can be unreliable
  // so we'll try to default to desktop
  const isDesktop = !isMobile && !isTablet;

  return { isMobile, isTablet, isDesktop, isMobileNavBar };
};

export const GlobalDataProvider = ({ language, altUrls = [], children }) => {
  const results = useStaticQuery(query);
  const { availableLanguages } = useLanguage();

  const breakpoints = useBootstrapBreakpoints();

  const { translations, ...data } = useMemo(
    () => processData({ results, language, altUrls, availableLanguages }),
    [results, language, altUrls, availableLanguages]
  );
  return (
    <LanguageProvider
      data={{
        language,
        translations,
      }}
    >
      <Ctx.Provider
        value={{
          ...data,
          ...breakpoints,
        }}
      >
        {children}
      </Ctx.Provider>
    </LanguageProvider>
  );
};

export const useGlobalData = () => {
  return useContext(Ctx);
};

export const query = graphql`
  query {
    allPrismicNewsPage(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          lang
          url
          data {
            date
            category
            page_title {
              text
            }
            body {
              ... on PrismicNewsPageBodyImage {
                id
                slice_type
                slice_label
                primary {
                  image {
                    thumbnails {
                      mobile_feature {
                        url
                      }
                      featured_size {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicAnimationPage {
      edges {
        node {
          lang
          url
          data {
            body {
              ... on PrismicAnimationPageBodyFrontpageAnimation {
                id
                primary {
                  text {
                    html
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
    allPrismicMenu {
      nodes {
        ...fragmentPrismicMenu
      }
    }

    allPrismicStrings {
      nodes {
        ...fragmentPrismicStrings
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const makeMenu = (results, id) => {
  const menu = results.find((m) => m.prismicId === id);

  if (!menu) {
    throw new Error(`cant find menu with id ${id}`);
  }

  const {
    data: { nav_links: items },
  } = menu;

  return items
    .filter((item) => !item.link.isBroken)
    .map(({ link_name: title, link, sub_menu: subMenu }) => {
      return {
        title: title.text,
        url: link.url,
        subMenu: extractSubMenu(subMenu, results),
        megaMenu: extractMegaMenu(subMenu),
      };
    });
};

const extractMegaMenu = (subMenu) => {
  const isMegaMenu =
    subMenu.id &&
    subMenu.type === "megamenu" &&
    subMenu.document &&
    subMenu.document.data;

  return isMegaMenu ? subMenu.document.data.body : [];
};

const extractSubMenu = (subMenu, results) => {
  const isNormalMenu = subMenu.id && subMenu.type === "menu";

  return isNormalMenu ? makeMenu(results, subMenu.id) : [];
};

const extractRawUrl = (string) => {
  const { key } = string;
  const url = string?.value?.raw[0]?.spans[0]?.data?.url;

  if (url && key) {
    return {
      key,
      url,
    };
  } else {
    return null;
  }
};

const processData = ({ results, language, altUrls, availableLanguages }) => {
  const {
    allPrismicMenu: { nodes: menuNodes },
    allPrismicStrings: { nodes: translationNodes },
    allPrismicNewsPage: { edges: newsNodes },
    allPrismicAnimationPage: { edges: animationEdges },
    site: {
      siteMetadata: { siteUrl },
    },
  } = results;


  const allIsNews = newsNodes
    .filter((i) => i.node.lang === "is")
    .map((item) => {
      const {
        date,
        page_title: { text: pageTitle },
      } = item.node.data;
      const url = item.node.url;
      const image = item.node.data.body
        .filter((i) => i.slice_type === "image")
        .map((i) => i.primary.image.thumbnails);
      return { pageTitle, date, url, image };
    });

    const allAnimations = animationEdges
    .map((item) => {
      const {
        text: { html: text },
      } = item.node.data.body[0].primary;
      return { text };
    });


  const allEnNews = newsNodes
    .filter((i) => i.node.lang === "en-gb")
    .map((item) => {
      const {
        date,
        page_title: { text: pageTitle },
      } = item.node.data;
      const url = item.node.url;
      const image = item.node.data.body
        .filter((i) => i.slice_type === "image")
        .map((i) => i.primary.image.thumbnails);
      return { pageTitle, date, url, image };
    });
  
  const latestEnNews = allEnNews.slice(0, 3);
  const latestIsNews = allIsNews.slice(0, 3);

  const mainMenu = menuNodes
    .filter((menu) => langCompare(menu.lang, language))
    .find((menu) => menu.data.menu_name === "mainmenu");

  if (!mainMenu) {
    console.log(menuNodes, language);
    throw new Error("mainmenu not found");
  }

  return {
    translations: makeTranslations(translationNodes),
    mainMenu: makeMenu(menuNodes, mainMenu.prismicId),
    isSelfOrChild,
    langMenu: makeLangMenu(language, availableLanguages, altUrls),
    siteUrl,
    latestEnNews,
    latestIsNews,
    allEnNews,
    allIsNews,
    stringUrls: makeStringUrls(translationNodes),
    allAnimations: allAnimations
  };
};

const makeLangMenu = (language, availableLanguages, altUrls) => {
  return [
    {
      title: language,
      megaMenu: null,
      subMenu: availableLanguages.map((l) => {
        const found = altUrls.find((entry) => langCompare(entry.lang, l));

        return {
          title: l,
          // todo: find some way of figuring out the frontpage url for this lang
          url: found ? found.url : "/",
        };
      }),
    },
  ];
};

const makeStringUrls = (nodes) => {
  const stringUrls = {};
  for (const node of nodes) {
    const {
      lang,
      data: { group_name: groupName, strings },
    } = node;
    const langKey = lang.substring(0, 2);

    for (const string of strings) {
      const extraction = extractRawUrl(string);
      if (extraction) {
        stringUrls[`${langKey}|${groupName}|${extraction.key}`] =
          extraction.url;
      }
    }
  }
  return stringUrls;
};

/**
 * takes a list of translations from the prismic query and turns it into a data
 * type compatible with our strings in the i18n plugin
 * @param {array} gatsby node list
 */
const makeTranslations = (nodes) => {
  return nodes.reduce((acc, node) => {
    const {
      lang,
      data: { group_name: groupName, strings },
    } = node;

    const langKey = lang.substring(0, 2);

    const keyedStrings = strings.reduce((acc, { key, value: { text } }) => {
      acc[key] = text;

      return acc;
    }, {});

    return {
      ...acc,
      [langKey]: {
        ...(acc[langKey] || {}),
        [groupName]: keyedStrings,
      },
    };
  }, {});
};

const isSelfOrChild = (targetUrl, menuItem) => {
  if (menuItem.url === targetUrl) {
    return true;
  }

  if (menuItem.subMenu) {
    return !!menuItem.subMenu.find((menuItem) =>
      isSelfOrChild(targetUrl, menuItem)
    );
  }

  return false;
};
