import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow,
  handleImgixUrl
} from '@kogk/common'
import cn from 'classnames'
import Img from 'gatsby-image'
import styles from './Map.module.scss'
import { useGlobalData } from '@src/data/global-data'

export default ({ mapImage, mobile }) => {
  const { isMobile } = useGlobalData()
  const image = isMobile ? mobile : mapImage
  // TODO::when ready, add real map

  return (
    <FancyShow>
      <Container fluid>
        <Row className='justify-content-center'>
          <Col
            col={12}
            className={cn(
              styles.mapWrapper,
              'mx-0 px-0 pb-md-5 pt-4'
            )}
          >
            <div className={styles.map}>
              {image &&
                <Img
                  loading='lazy'
                  fluid={handleImgixUrl(image).gatsbyImageFluid()}
                />
              }
            </div>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
